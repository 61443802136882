<template>
  <div>
    <b-alert
      :show="form.errors && form.errors.length > 0"
      variant="light"
      class="alert red lighten-4"
    >
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-danger"></i>
      </div>
      <div class="alert-text">
        <div v-for="err in form.errors" :key="err">
          {{ err }}
        </div>
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form
              ref="form"
              v-model="form.valid"
              lazy-validation
              @submit.prevent="submitForm"
            >
              <div class="row">
                <div class="col-3">
                  <v-select
                    v-model="form.memberClass"
                    :disabled="form.loading"
                    :label="$t('MEMBER_CARD.GENERATE.MEMBER_CLASS')"
                    :clearable="true"
                    :items="form.memberClassItems"
                    :rules="form.memberClassRules"
                    required
                  ></v-select>
                </div>
                <div class="col-3">
                  <v-text-field
                    v-model="form.quantity"
                    :disabled="form.loading"
                    :label="$t('MEMBER_CARD.GENERATE.QUANTITY')"
                    :rules="form.quantityRules"
                    required
                    type="number"
                    min="0"
                  ></v-text-field>
                </div>
                
                <div class="col-3">
                  <v-autocomplete
                    v-model="form.generateRefCode"
                    :disabled="form.loading"
                    :items="form.lotItems"
                    :loading="form.lotLoading"
                    :search-input.sync="form.lotGenerate"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('MEMBER_CARD.GENERATE.LOT')"
                    :placeholder="$t('SHARED.START_TYPING_TO_SEARCH')"
                    return-object
                    clearable
                  ></v-autocomplete>
                  <!-- :items="form.generateRefCodeItems" -->
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <b>{{ $t("MEMBER_CARD.GENERATE.EXAMPLE") }}</b>
                  <span>ST</span>
                  <span class="kt-font-bolder">{{ numMemberClass }}</span>
                  <!-- <span
                    ><u>{{ numExpireMonth }}</u></span
                  > -->
                  <span>000000</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <v-btn
                    :disabled="!form.valid || form.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon v-if="!form.loading" left>fa fa-random</v-icon>
                    <v-icon v-if="form.loading" left
                      >fa fa-spinner fa-spin</v-icon
                    >
                    {{ $t("MEMBER_CARD.GENERATE.GENERATE_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="form.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>fa fa-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>

                  <v-dialog v-model="form.dialog" persistent max-width="300">
                    <v-card>
                      <v-card-title class="headline">
                        {{
                          $t("MEMBER_CARD.GENERATE.CONFIRM_DIALOG_HEADER")
                        }}</v-card-title
                      >
                      <v-card-text>
                        <template v-if="!form.loading">
                          {{ $t("MEMBER_CARD.GENERATE.CONFIRM_DIALOG_TEXT") }}
                        </template>
                        <template v-else>
                          <div class="text-center">
                            <v-icon>fa fa-spinner fa-spin</v-icon>
                            {{ $t("SHARED.PROCESSING") }}
                          </div>
                        </template>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          :disabled="form.loading"
                          color="grey darken-1"
                          text
                          @click="form.dialog = false"
                          >{{ $t("SHARED.RECHECK_BUTTON") }}
                        </v-btn>
                        <v-btn
                          :disabled="form.loading"
                          color="green darken-1"
                          text
                          @click="confirmForm"
                          >{{ $t("SHARED.CONFIRM_BUTTON") }}</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="form.downloading" persistent width="300">
                    <v-card>
                      <v-card-title class="headline">
                        {{
                          $t("MEMBER_CARD.GENERATE.DOWNLOAD_DIALOG_HEADER")
                        }}</v-card-title
                      >
                      <v-card-text>
                        {{ $t("MEMBER_CARD.GENERATE.DOWNLOAD_DIALOG_TEXT") }}
                        <!-- <br />
                        BATCH ID: {{ form.downloadingBatchId }}
                        <br /> -->
                        <a
                          target="_batch"
                          href="#"
                          @click.prevent="openDownloadWindow"
                          >{{ $t("SHARED.DOWNLOAD_BUTTON") }}</a
                        >
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="grey darken-1"
                          text
                          @click="closeDownloadDialog"
                          >{{ $t("SHARED.CLOSE_BUTTON") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </v-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  components: {
    KTPortlet,
  },
  data() {
    return {
      form: {
        valid: true,
        loading: false,
        dialog: false,
        downloading: false,
        downloadingBatchId: null,
        errors: [],
        generateRefCodeSearch: "",
        generateRefCodeLoading: false,
        // generateRefCodeItems: "",
        memberClass: null,
        memberClassItems: [
          {
            text: this.$t("SYS_VARIABLE.MEMBER_CLASS_NORMAL"),
            value: "MEMBER_CLASS_NORMAL",
          },
          {
            text: this.$t("SYS_VARIABLE.MEMBER_CLASS_GOLD"),
            value: "MEMBER_CLASS_GOLD",
          },
        ],
        memberClassRules: [
          (v) => !!v || this.$t("MEMBER_CARD.GENERATE.MEMBER_CLASS_REQUIRED"),
        ],
        quantity: null,
        quantityRules: [
          (v) => !!v || this.$t("MEMBER_CARD.GENERATE.QUANTITY_REQUIRED"),
          (v) => Number(v) > 0 || this.$t("MEMBER_CARD.GENERATE.QUANTITY_GT_0"),
        ],
        expireMonth: null,
        expireMonthModal: false,
        expireMonthRules: [
          (v) => !!v || this.$t("MEMBER_CARD.GENERATE.EXPIRE_MONTH_REQUIRED"),
        ],
        generateRefCode: "",
        generateRefCodeRules: [
          (v) =>
            !!v || this.$t("MEMBER_CARD.GENERATE.GENERATE_REF_CODE_REQUIRED"),
          // (v) =>
          //   (v && v.length >= 10) ||
          //   this.$t("MEMBER_CARD.GENERATE.GENERATE_REF_CODE_MIN_10_CHAR"),
        ],
        lot: null,
        lotGenerate: "",
        roleLoading: false,
        lotItems: [],
      },
    };
  },
  methods: {
    openDownloadWindow() {
      window.open(
        "/Files/Download/MemberCard/" +
          this.form.downloadingBatchId +
          "?token=" +
          JwtService.getToken(),
        "_blank"
      );
    },
    closeDownloadDialog() {
      // not close but redirect to search page
      this.$router.push({ name: "SearchMemberCards" });
    },
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.form.dialog = true;
      }
    },
    getLotFromApi() {
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("/Api/Lot/LotOptions", {
          query: this.form.lotSearch,
        })
        .then(({data}) => {
          resolve(data);
        })
        .finally(() => {
          this.form.lotLoading = false;
        })
      })
    },
    async confirmForm() {
      this.form.loading = true;
      this.form.errors = [];

      ApiService.setHeader();
      ApiService.post("/Api/MemberCards/Generate", {
        memberClassCode: this.form.memberClass,
        lotId: this.form.generateRefCode
          ? parseInt(this.form.generateRefCode.value)
          : null,
        quantity: Number(this.form.quantity),
        // lotId: this.form.lotId
      })
        .then(({ data }) => {
          if (data.status) {
            // close confirm dialog
            this.form.dialog = false;
            // force download excel
            this.form.downloading = true;
            this.form.downloadingBatchId = data.data;
          } else {
            this.form.loading = false;
            this.form.dialog = false;
            this.form.errors.push(!!data.message || "Unknown error occurs");
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.form.errors.push(response.data.title);
          } else {
            this.form.errors.push("Unknown error occurs");
          }
          this.form.loading = false;
          this.form.dialog = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.MEMBER_CARD.SECTION"), route: "/MemberCards" },
      { title: this.$t("MENU.MEMBER_CARD.GENERATE") },
    ]);
  },
  computed: {
    title() {
      return this.$t("MENU.MEMBER_CARD.GENERATE");
    },
    minExpireMonth() {
      return new Date().toISOString().substr(0, 7);
    },
    numMemberClass() {
      return this.form.memberClass == "MEMBER_CLASS_GOLD" ? "G" : "S";
    },
    numExpireMonth() {
      return this.form.expireMonth
        ? this.form.expireMonth.substr(2, 2)
        : new Date().toISOString().substr(2, 2);
    },
  },
  watch: {
    "form.lotGenerate": {
      handler() {
        this.getLotFromApi().then((data) => {
          this.form.lotItems = data;
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
